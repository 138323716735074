<template>
  <a-modal
    :visible="visibleOrderDelivery"
    title="销售订单出库"
    :maskClosable="false"
    :width="1000"
    @cancel="$emit('cancel')"
    :destroyOnClose="true"
    @ok="OrderDelivery"
    :okText="'确定'"
    :cancel-button-props="{ style: { display: 'none' } }"
  >
    <div>
      <div class="tip">操作出库后，将生成销售出库单</div>
      <div class="sel">
        <label>请选择出库仓库：</label>
        <a-select :value="warehouseID" style="width: 320px">
          <a-select-option
            v-for="item in warehouseDropdownList"
            :value="item.id"
            :key="item.id"
            @click="handleChangewarehouse(item)"
            >{{ item.code }}
          </a-select-option>
        </a-select>
      </div>

      <a-table
        :columns="columns"
        :data-source="dataSource"
        :rowKey="(record) => record.goodID"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <template slot="creationTime" slot-scope="text">
          {{ text | filterDate }}
        </template>
        <template slot="currentIssueNum" slot-scope="text, record">
          <editable-cell
            :text="text"
            @change="onCellChange(record, 'currentIssueNum', $event)"
          />
        </template>
      </a-table>
    </div>
  </a-modal>
</template>
<script>
import EditableCell from "./EditableCell.vue";

const columns = [
  {
    title: "商品名称",
    dataIndex: "goodName",
  },
  {
    title: "销售数量",
    dataIndex: "orderNum",
  },
  {
    title: "已出库数量",
    dataIndex: "quantityNum",
  },
  {
    title: "可用库存",
    dataIndex: "availableNum",
  },
  {
    title: "本次出库数量",
    dataIndex: "currentIssueNum",
    scopedSlots: { customRender: "currentIssueNum" },
  },
  {
    title: "交货日期",
    dataIndex: "deliveryData",
    scopedSlots: { customRender: "creationTime" },
  },
];

import { getQuantityNumlist, updateQuantity } from "@/api/salesOrderDetail";
import { getDropdownList } from "@/api/warehouseManage";

export default {
  components: {
    EditableCell,
  },
  props: {
    visibleOrderDelivery: { type: Boolean, default: () => false },
    orderDeliverySalesId: { type: String, default: "" },
  },
  created() {
    this.getDropdownList();
  },
  data() {
    return {
      dataSource: [],
      columns,

      warehouseID: "",
      warehouseDropdownList: [],
      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
      },
    };
  },
  filters: {
    filterDate(val) {
      if (val) {
        let dt = val.substring(0, 19).replace("T", " ");
        return dt;
      } else {
        return "";
      }
    },
  },
  methods: {
    onCellChange(record, dataIndex, value) {},
    getDropdownList() {
      getDropdownList().then((res) => {
        this.warehouseDropdownList = res;
        if (res.length > 0) this.warehouseID = res[0].id;
      });
    },
    getQuantityNumlist(parm) {
      if (!parm) parm = {};
      getQuantityNumlist(parm).then((res) => {
        this.dataSource = [];
        res.items.forEach((item) => {
          item.currentIssueNum = 0;
          this.dataSource.push(item);
        });
      });
    },
    handleChangewarehouse(item) {
      this.warehouseID = item.id;
    },
    /**
     * 列表切换分页事件
     * @param {obj} pagination [当前分页参数]]
     */
    handleTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination ? pagination.current : pager.current;
      this.pagination = pager;
      var parm = {
        skipCount: (this.pagination.current - 1) * this.pagination.pageSize,
      };
      this.getQuantityNumlist(parm);
    },
    OrderDelivery() {
      var pram = {};
      var datas = [];
      this.dataSource.forEach((item) => {
        var data = {
          orderDetailID: item.id,
          num: item.currentIssueNum,
        };
        datas.push(data);
      });
      pram.warehouseID = "3fa85f64-5717-4562-b3fc-2c963f66afa6"; //仓库ID
      pram.orderDetails = datas; //仓库ID
      updateQuantity(pram).then((res) => {
        this.$message.success("销售订单发货成功");
      });
    },
  },
  watch: {
    orderDeliverySalesId: {
      handler() {
        if (this.orderDeliverySalesId != "") {
          this.getQuantityNumlist({
            salesID: this.orderDeliverySalesId,
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.tip {
  background: #eee;
  padding: 8px;
  margin-bottom: 16px;
}

.sel {
  margin-bottom: 16px;
  label {
    font-weight: bold;
  }
}
</style>
