<template>
  <div class="importsort">
    <h3>选择文件</h3>
    <a-upload-dragger
      name="file"
      :showUploadList="false"
      :before-upload="beforeUpload"
      :file-list="fileList"
      @change="handleChange"
    >
      <p class="ant-upload-drag-icon">
        <a-icon type="inbox" />
      </p>
      <p class="ant-upload-text">
        可以直接拖拽上传，或者
        <span>选择文件</span>、<span>选择文件夹</span>进行上传
      </p>
    </a-upload-dragger>
    <a-row class="info-row">
      <a-col :span="12">
        <h3>导入明细</h3>
      </a-col>
      <a-col :span="12">
        <a
          class="ant-btn-primary ant-btn"
          href="/SalesOrderTemplate.xlsx"
          download="SalesOrderTemplate.xlsx"
          >模板下载</a
        >
        <a-button type="primary" disabled @click="clearClick"
          >清空列表</a-button
        >
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24" class="dec">
        <span
          >共 <strong>{{ salesTotalNum }}</strong> 条订单</span
        >
        <a-select
          :value="sales.sourceNo"
          style="width: 320px"
          show-search
          :default-active-first-option="false"
          :filter-option="false"
          :not-found-content="null"
        >
          <a-select-option
            v-for="(item, index) in salesOrders"
            :value="item.sourceNo == null ? index : item.sourceNo"
            :key="index"
            @click="handleClick(item)"
            >{{ getSourceNo(item) }}
          </a-select-option>
        </a-select>
        <a class="del-sel">
          <a-icon @click="selDel" type="delete" :style="{ fontSize: '26px' }" />
        </a>
      </a-col>
    </a-row>
    <a-row class="tit">
      <a-col :span="6">
        <label>下单日期：</label>
        <span>{{ sales.orderDate }}</span>
      </a-col>
      <a-col :span="6">
        <label>客户名称：</label>
        <span>{{ sales.customerName }}</span>
      </a-col>
      <a-col :span="6">
        <label>订单总价：</label>
        <span>{{ sales.total }}</span>
      </a-col>
      <a-col :span="6">
        <label>订单来源：</label>
        <span>{{ sales.sourceType }}</span>
      </a-col>
    </a-row>
    <a-row class="tit">
      <a-col :span="24">
        <label>错误信息：</label>
        <span>{{ sales.errors }}</span>
      </a-col>
    </a-row>
    <a-table
      :columns="columns"
      :data-source="importExceldata"
      :rowKey="(row, index) => index"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <template slot="operation" slot-scope="text, record, index">
        <a-space :size="20">
          <a>
            <a-icon
              type="delete"
              :style="{ fontSize: '26px' }"
              @click="rowDel(index)"
            />
          </a>
        </a-space>
      </template>
    </a-table>
  </div>
</template>
<script>
import { ImportSalesOrderAsync } from "@/api/salesOrder";
export default {
  data() {
    return {
      importExceldata: [],
      columns: [
        {
          title: "错误信息",
          dataIndex: "errors",
        },
        {
          title: "商品名称",
          dataIndex: "goodName",
        },
        {
          title: "购买数量",
          dataIndex: "orderNum",
        },
        {
          title: "单价",
          dataIndex: "price",
        },
        {
          title: "交期",
          dataIndex: "deliveryData",
        },
        {
          title: "是否定制",
          dataIndex: "isCustomized",
        },
        {
          title: "删除",
          dataIndex: "operation",
          align: "center",
          scopedSlots: { customRender: "operation" },
        },
      ],
      salesOrders: [],
      salesTotalNum: 0,
      sales: {
        sourceNo: "",
        orderDate: "",
        salesName: "",
        customerName: "",
        sourceType: "",
        total: "",
      },
      fileList: [],
      defaultValue: "",

      pagination: {
        current: 1,
        total: 0,
        pageSize: 10,
      },
    };
  },
  methods: {
    //分页
    handleTableChange(pagination) {
      console.log(pagination);
      this.pagination.current = pagination.current; //当前第几页
      this.pagination.pageSize = pagination.pageSize;
      this.SkipCount = (pagination.current - 1) * pagination.pageSize; //从总数据的第几条数据开始取数据
      this.MaxResultCount = pagination.pageSize; //一页多少条
    },
    getSourceNo(item) {
      return item.sourceNo == "" || item.sourceNo == null
        ? "空单号"
        : item.sourceNo;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      return false;
    },
    handleChange(info) {
      const formData = new FormData();
      formData.append("file", info.file);
      ImportSalesOrderAsync(formData).then((res) => {
        this.salesOrders = res;
        if (res.length > 0) {
          this.salesTotalNum = res.length;
          this.sales = res[0];
          this.sales.total = 0;
          debugger
          res[0].importSalesOrderDetails.forEach((item) => {
            this.sales.total += item.orderNum * item.price;
          });
          this.importExceldata = res[0].importSalesOrderDetails;
        }
      });
    },
    rowDel(index) {
      let pageSize = this.pagination.pageSize;
      let current = this.pagination.current - 1;
      let num = current * pageSize + index;
      this.importExceldata.splice(num, 1);

      this.fileList.splice(num, 1);

      this.pagination.current = 1;
    },
    selDel() {
      var salesOrder = this.salesOrders;
      this.salesOrders = salesOrder.filter(
        (item) => item.sourceNo !== this.sales.sourceNo
      );
      if (this.salesOrders.length > 0) this.handleClick(this.salesOrders[0]);
      else {
        this.sales = {};
        this.importExceldata = [];
      }
    },
    clearClick() {
      this.importExceldata = [];
    },
    handleClick(item) {
      this.sales = item;
      this.sales.total = 0;
      item.importSalesOrderDetails.forEach((item) => {
        this.sales.total += item.orderNum * item.price;
      });
      this.importExceldata = item.importSalesOrderDetails;
    },
  },
};
</script>
<style lang="scss" scoped>
.ant-upload-text span {
  color: #1890ff;
}

.importsort h3 {
  margin-bottom: 20px;
}

.info-row {
  margin: 20px 0;
}

.info-row .ant-btn {
  margin-right: 15px;
}

.info-row .ant-btn:last-child {
  margin-right: 0;
}

.info-row .ant-col-12:nth-child(2) {
  text-align: right;
}

.dec {
  margin-bottom: 16px;
  span {
    margin-right: 26px;
    strong {
      color: #1890ff;
    }
  }
  .del-sel {
    margin-left: 30px;
    position: relative;
    top: 3px;
  }
}

.tit {
  margin-bottom: 16px;
  label {
    font-weight: bold;
  }
}
</style>
