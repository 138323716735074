<template>
  <div class="bgStyle wrapper">
    <div class="head">
      <a-row :style="{ marginBottom: '12px' }">
        <a-col :xxl="6" :xl="7" :lg="8">
          <label> 订单编号： </label>
          <a-input
            placeholder="请输入订单编号"
            v-model="orderCode"
            style="width: 210px"
            allowClear
          />
        </a-col>
        <a-col :xxl="6" :xl="7" :lg="8">
          <label class="label2"> 下单日期： </label>
          <a-range-picker
            style="width: 210px"
            format="YYYY-MM-DD"
            @change="onChange"
            valueFormat="YYYY-MM-DD"
          />
        </a-col>
        <!-- <a-col :xxl="6" :xl="7" :lg="8">
          <label class="label3">
            期望到货日期：
          </label>
          <a-range-picker
            v-model="expectTime"
            style="width: 210px"
            format="YYYY-MM-DD"
          />
        </a-col> -->
        <a-col :xxl="6" :xl="7" :lg="8">
          <label class="label3" style="width: 76px"> 客户名称：</label>
          <a-input
            placeholder="请输入客户名称"
            v-model="customer"
            style="width: 210px"
            allowClear
          />
        </a-col>
      </a-row>
      <a-row :style="{ marginBottom: '12px' }">
        <a-col :xxl="6" :xl="7" :lg="8">
          <label class="label1"> 订单来源： </label>
          <a-select
            style="width: 210px"
            ref="select"
            placeholder="请选择订单来源"
            allowClear
          >
            <a-select-option
              v-for="item in orderSource"
              :value="item.id"
              :key="item.id"
              @click="arrDocTypesclick(item)"
              >{{ item.codeName }}</a-select-option
            >
          </a-select>
        </a-col>
        <a-col :xxl="6" :xl="7" :lg="8">
          <a-button type="primary" @click="onSearch">查询</a-button>
        </a-col>
      </a-row>
    </div>
    <a-row :style="{ marginBottom: '12px' }">
      <a-col :span="24">
        <a-space>
          <a-button type="primary" @click="addOrder">添加订单</a-button>
          <a-button @click="openImportModal">导入订单</a-button>
        </a-space>
      </a-col>
    </a-row>
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :rowKey="(record) => record.id"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <a-card slot="filterOrder">
        <a-checkbox-group v-model="orderStatus">
          <a-checkbox :value="10"> 待确认 </a-checkbox>
          <a-checkbox :value="20"> 已确认 </a-checkbox>
          <a-checkbox :value="30"> 部分出库 </a-checkbox>
          <a-checkbox :value="40"> 全部出库 </a-checkbox>
          <a-checkbox :value="50"> 订单完成 </a-checkbox>
          <a-checkbox :value="60"> 已取消 </a-checkbox>
        </a-checkbox-group>
        <div class="filter-btn">
          <a-space>
            <a-button type="primary" icon="search" @click="onSearch">
              搜索
            </a-button>
            <a-button @click="onReset"> 重置 </a-button>
          </a-space>
        </div>
      </a-card>
      <template slot="docSize" slot-scope="text, record">
        <a v-if="record.status == 10" @click="openOrder(record)">待确认</a>
        <a v-if="record.status == 20" @click="openOrder(record)">已确认</a>
        <a v-if="record.status == 30" @click="openOrder(record)">部分出库</a>
        <a v-if="record.status == 40" @click="openOrder(record)">全部出库</a>
        <a v-if="record.status == 50" @click="openOrder(record)">订单完成</a>
        <a v-if="record.status == 60" @click="openOrder(record)">已取消</a>
      </template>

      <a-card slot="filterPayment">
        <a-checkbox-group v-model="paymentStatus">
          <a-checkbox :value="1"> 待付款 </a-checkbox>
          <a-checkbox :value="2"> 部分付款 </a-checkbox>
          <a-checkbox :value="3"> 付款完成 </a-checkbox>
        </a-checkbox-group>
        <div class="filter-btn">
          <a-space>
            <a-button type="primary" icon="search" @click="onSearch">
              搜索
            </a-button>
            <a-button @click="onReset"> 重置 </a-button>
          </a-space>
        </div>
      </a-card>
      <template slot="docVersion" slot-scope="text, record">
        <editableselect-cell
          :text="text"
          :status="selectPaymentStatus"
          @change="onCellChange(record, 'docVersion', $event)"
        />
      </template>

      <a-card slot="filterProduction">
        <a-checkbox-group v-model="productionStatus">
          <a-checkbox :value="10"> 待生产 </a-checkbox>
          <a-checkbox :value="20"> 部分生产 </a-checkbox>
          <a-checkbox :value="30"> 生产完成 </a-checkbox>
          <a-checkbox :value="40"> 无需生产 </a-checkbox>
        </a-checkbox-group>
        <div class="filter-btn">
          <a-space>
            <a-button type="primary" icon="search" @click="onSearch">
              搜索
            </a-button>
            <a-button @click="onReset"> 重置 </a-button>
          </a-space>
        </div>
      </a-card>
      <template slot="docFormat" slot-scope="text, record">
        <a v-if="record.productionStatus == 10" @click="openProduction(record)"
          >待生产</a
        >
        <a v-if="record.productionStatus == 20" @click="openProduction(record)"
          >部分生产</a
        >
        <a v-if="record.productionStatus == 30" @click="openProduction(record)"
          >生产完成</a
        >
        <a v-if="record.productionStatus == 40" @click="openProduction(record)"
          >无需生产</a
        >
      </template>

      <template slot="operation" slot-scope="text, record">
        <a-dropdown>
          <a-menu slot="overlay">
            <a-menu-item key="1" @click="openOrderDelivery(record)">
              订单发货
            </a-menu-item>
            <a-menu-item key="2" @click="openProductionDelivery(record)">
              订单生产
            </a-menu-item>
            <a-menu-item key="3" @click="detailOrder(record)">
              编辑
            </a-menu-item>
          </a-menu>
          <a> 操作 <a-icon type="down" /> </a>
        </a-dropdown>
      </template>
      <template slot="creationTime" slot-scope="text">
        {{ text | filterDate }}
      </template>
    </a-table>

    <a-modal
      v-model="visibleOrder"
      title="订单状态跟踪"
      :width="800"
      :footer="null"
      :destroyOnClose="true"
    >
      <order-modal />
    </a-modal>

    <a-modal
      v-model="visibleProduction"
      title="生产状态跟踪"
      :width="800"
      :footer="null"
      :destroyOnClose="true"
    >
      <production-modal />
    </a-modal>

    <order-delivery-modal
      ref="orderDelivery"
      :visibleOrderDelivery="visibleOrderDelivery"
      :orderDeliverySalesId="orderDeliverySalesId"
      @submited="
        () => {
          this.visibleOrderDelivery = false;
          this.orderDeliverySalesId = '';
        }
      "
      @cancel="
        () => {
          this.visibleOrderDelivery = false;
          this.orderDeliverySalesId = '';
        }
      "
    />

    <production-delivery-modal
      ref="productionDelivery"
      :visibleProductionDelivery="visibleProductionDelivery"
      :productionDeliverySalesId="productionDeliverySalesId"
      @submited="
        () => {
          this.visibleProductionDelivery = false;
          this.productionDeliverySalesId = '';
        }
      "
      @cancel="
        () => {
          this.visibleProductionDelivery = false;
          this.productionDeliverySalesId = '';
        }
      "
    />

    <a-modal
      v-model="visibleImport"
      title="导入订单"
      :width="1000"
      :destroyOnClose="true"
      :maskClosable="false"
      okText="开始上传"
      :cancel-button-props="{ style: { display: 'none' } }"
      @ok="handleUploadOK"
    >
      <import-order-modal ref="refImportOrder" />
    </a-modal>
  </div>
</template>

<script>
import EditableselectCell from "./components/EditableselectCell.vue";
import OrderModal from "./components/OrderModal.vue";
import ProductionModal from "./components/ProductionModal.vue";
import OrderDeliveryModal from "./components/OrderDeliveryModal.vue";
import ProductionDeliveryModal from "./components/ProductionDeliveryModal.vue";
import ImportOrderModal from "./components/ImportOrderModal.vue";
import {
  getSalesOrderList,
  updateStateSalesOrder,
  CreateImportAsync,
} from "@/api/salesOrder";
import { getListByCodeKeyPath } from "@/api/code.js";

const columns = [
  {
    title: "订单编号",
    dataIndex: "salesNo",
    scopedSlots: { customRender: "salesNo" },
    ellipsis: true,
  },
  {
    title: "来源单号",
    dataIndex: "sourceNo",
    scopedSlots: { customRender: "sourceNo" },
    ellipsis: true,
  },
  {
    title: "下单日期",
    dataIndex: "orderDate",
    scopedSlots: { customRender: "docName" },
    ellipsis: true,
  },
  {
    title: "客户名称",
    dataIndex: "customerName",
    ellipsis: true,
  },
  {
    title: "订单来源",
    dataIndex: "sourceType",
    scopedSlots: { customRender: "docType" },
    width: 100,
    ellipsis: true,
  },
  {
    title: "商品名称",
    dataIndex: "goodNames",
    width: 100,
    ellipsis: true,
  },
  {
    title: "订单总价",
    dataIndex: "totalPrice",
  },
  {
    title: "订单状态",
    dataIndex: "status",
    key: "status",
    scopedSlots: {
      filterDropdown: "filterOrder",
      customRender: "docSize",
    },
  },
  {
    title: "付款状态",
    dataIndex: "paymentStatus",
    scopedSlots: {
      filterDropdown: "filterPayment",
      customRender: "docVersion",
    },
    width: 200,
  },
  {
    title: "生产状态",
    dataIndex: "productionStatus",
    scopedSlots: {
      filterDropdown: "filterProduction",
      customRender: "docFormat",
    },
  },
  {
    title: "操作",
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
    width: 100,
  },
];

export default {
  name: "SalesManageIndex",
  components: {
    EditableselectCell,
    OrderModal,
    ProductionModal,
    OrderDeliveryModal,
    ProductionDeliveryModal,
    ImportOrderModal,
  },
  data() {
    return {
      dataSource: [],
      columns,
      selectPaymentStatus: [
        { value: 1, version: "待付款" },
        { value: 2, version: "部分付款" },
        { value: 3, version: "付款完成" },
      ],
      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
        skipCount: 0,
      },

      orderSource: [],

      orderCode: "",
      orderTime: [],
      sourceType: "",
      // expectTime: [],
      customer: "",
      isCustomization: undefined,

      orderStatus: [],
      paymentStatus: [],
      productionStatus: [],

      visibleOrder: false,
      visibleProduction: false,

      visibleOrderDelivery: false,
      orderDeliverySalesId: "",
      visibleProductionDelivery: false,
      productionDeliverySalesId: "",

      visibleImport: false,
    };
  },
  filters: {
    filterDate(val) {
      if (val) {
        let dt = val.substring(0, 10).replace("T", " ");
        return dt;
      } else {
        return "";
      }
    },
  },
  created() {
    this.getSalesOrderList();
    var _this = this;
    getListByCodeKeyPath("HS_SourceType")
      .then((res) => {
        _this.orderSource = res;
      })
      .catch(() => {
        _this.$message.error("获取来源订单类型失败，请重试～！");
      });
  },
  methods: {
    getSalesOrderList(parm) {
      var _this = this;
      this.loading = true;
      if (!parm) {
        parm = {};
      }
      parm.maxResultCount = this.pagination.pageSize;
      parm.skipCount = (this.pagination.current - 1) * this.pagination.pageSize;
      getSalesOrderList(parm).then((res) => {
        _this.dataSource = res.items;
      });
    },
    arrDocTypesclick(item) {
      this.sourceType = item.codeName;
    },
    onChange(date, dateString) {
      this.orderTime = date;
    },

    onCellChange(record, dataIndex, value) {
      updateStateSalesOrder(record.id, { paymentStatus: value }).then(
        (res) => {}
      );
      console.log("onCellChange", record, dataIndex, value);
    },
    handleTableChange() {},
    onSearch() {
      var param = {
        filterNo: this.orderCode,
        customerName: this.customer,
        sourceType: this.sourceType,
        status: this.orderStatus,
        paymentStatus: this.paymentStatus,
        productionStatus: this.productionStatus,
      };
      if (this.orderTime.length > 0) {
        param.orderDate = this.orderTime[0];
        param.endOrderDate = this.orderTime[1];
      }
      this.getSalesOrderList(param);
    },
    addOrder() {
      this.$router.push({
        name: "SalesManageNew",
      });
    },
    detailOrder(record) {
      this.$router.push({
        name: "SalesManageDetail",
        params: {
          saleOrderId: record.id,
        },
      });
    },
    openImportModal() {
      this.visibleImport = true;
    },
    onReset() {},
    openOrder(record) {
      this.visibleOrder = true;
    },
    openProduction(record) {
      this.visibleProduction = true;
    },
    openOrderDelivery(record) {
      this.orderDeliverySalesId = record.id;
      this.visibleOrderDelivery = true;
    },
    openProductionDelivery(record) {
      this.productionDeliverySalesId = record.id;
      this.visibleProductionDelivery = true;
    },
    handleUploadOK() {
      var importSalesOrders = this.$refs.refImportOrder.salesOrders;
      var parm = { importSalesOrders: this.$refs.refImportOrder.salesOrders };
      CreateImportAsync(parm).then((res) => {
        this.$message.success("上传成功");
        this.onSearch();
        this.visibleImport = false;
      });
      
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  background: #fff !important;
  .editable-row-operations a {
    margin-right: 8px;
  }

  .head {
    margin-bottom: 16px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    label {
      font-size: 14px;
    }

    .label2 {
      width: 76px;
      display: inline-block;
    }

    .label3 {
      width: 102px;
      display: inline-block;
    }

    .ant-col {
      display: flex;
      align-items: center;
      margin-right: 8px;
    }
  }
}

.filter-btn {
  border-top: 1px solid #ddd;
  margin-top: 8px;
  padding-top: 16px;
}
</style>
