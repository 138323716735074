import {
    postRequest,
    updateRequest,
    getRequest,
    deleteRequest
} from "@/utils/http";

/** 查询销售订单明细集合 */
export const getSalesOrderDetailList = (parm) => getRequest(`/common/SalesOrderDetail/getList`, parm);

/** 查询销售订单明细集合生产数据 */
export const getProductNumlist = (parm) => getRequest(`/common/SalesOrderDetail/getProductNumlist`, parm);

/** 查询销售订单明细集合发货数量 */
export const getQuantityNumlist = (parm) => getRequest(`/common/SalesOrderDetail/getQuantityNumlist`, parm);

/** 新建销售订单明细 */
export const createSalesOrderDetail = (parm) => postRequest(`/common/SalesOrderDetail/create`, parm);

/** 修改销售订单明细 */
export const updateSalesOrderDetail = (id, parm) => updateRequest(`/common/SalesOrderDetail/update/${id}`, parm);

/** 修改销售订单明细生产数据 */
export const updateProduction = (parm) => postRequest(`/common/SalesOrderDetail/updateProduction/`, parm);

/** 修改销售订单明细发货数量 */
export const updateQuantity = (parm) => postRequest(`/common/SalesOrderDetail/updateQuantity`, parm);

/** 删除销售订单明细发货数量 */
export const DeleteSalesOrderDetail = (id) => deleteRequest(`/common/SalesOrderDetail/${id}`);
