<template>
  <div class="editable-cell">
    <div class="editable-cell-input-wrapper">
      <a-input-number
        :value="value"
        @change="handleChange"
        :min="0"
        :max="10"
        :precision="0"
      />
      <span class="editable-cell-tip">不超过200</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditableCell",
  props: {
    text: Number,
  },
  data() {
    return {
      value: this.text,
      // editable: false,
    };
  },
  methods: {
    handleChange(e) {
      console.log("handleChange", e);
      this.value = e || 0;

      this.$emit("change", this.value);
    },
    // check() {
    //   this.editable = false;
    //   this.$emit("change", this.value);
    // },
    // edit() {
    //   this.editable = true;
    // },
  },
};
</script>

<style lang="scss" scoped>
.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
  color: #1890ff;
}

.editable-cell-icon {
  line-height: 26px;
  // display: none;
}

.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}

.editable-cell-tip {
  color: #1890ff;
  margin-left: 8px;
}
</style>
