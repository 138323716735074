<template>
  <a-modal
    :visible="visibleProductionDelivery"
    title="销售订单生产"
    :maskClosable="false"
    :width="1000"
    @cancel="$emit('cancel')"
    :destroyOnClose="true"
    @ok="ProductionDelivery"
    :okText="'确定'"
    :cancel-button-props="{ style: { display: 'none' } }"
    ><div>
      <div class="tip">操作转生产后，将生成生产工单</div>
      <div class="sel">
        <label>请选择工厂：</label>
        <a-select
          :value="factoryID"
          style="width: 320px"
          @change="handleChange"
        >
          <a-select-option :value="1" :key="1">1号工厂</a-select-option>
          <a-select-option :value="2" :key="2">2号工厂</a-select-option>
        </a-select>
      </div>

      <a-table
        :columns="columns"
        :data-source="dataSource"
        :rowKey="(record) => record.id"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <template slot="creationTime" slot-scope="text">
          {{ text | filterDate }}
        </template>
        <template slot="transferProductionNum" slot-scope="text, record">
          <editable-cell
            :text="text"
            @change="onCellChange(record, 'transferProductionNum', $event)"
          />
        </template>
      </a-table>
    </div>
  </a-modal>
</template>
<script>
import EditableCell from "./EditableCell.vue";

const columns = [
  {
    title: "商品名称",
    dataIndex: "goodName",
  },
  {
    title: "销售数量",
    dataIndex: "orderNum",
  },
  {
    title: "已出库数量",
    dataIndex: "quantityNum",
  },
  {
    title: "可用库存",
    dataIndex: "availableNum",
  },
  {
    title: "半成品库存",
    dataIndex: "semiFinishedProducts",
  },
  {
    title: "生产中数量",
    dataIndex: "productionNum",
  },
  {
    title: "转生产数量",
    dataIndex: "transferProductionNum",
    scopedSlots: { customRender: "transferProductionNum" },
  },
  {
    title: "交货日期",
    dataIndex: "deliveryData",
    scopedSlots: { customRender: "deliveryData" },
  },
];

import { getProductNumlist, updateProduction } from "@/api/salesOrderDetail";

export default {
  components: {
    EditableCell,
  },
  props: {
    visibleProductionDelivery: { type: Boolean, default: () => false },
    productionDeliverySalesId: { type: String, default: "" },
  },
  data() {
    return {
      dataSource: [],
      columns,

      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
      },

      factoryID: 1,
    };
  },
  filters: {
    filterDate(val) {
      if (val) {
        let dt = val.substring(0, 19).replace("T", " ");
        return dt;
      } else {
        return "";
      }
    },
  },
  methods: {
    onCellChange(record, dataIndex, value) {
      console.log("onCellChange", record, dataIndex, value);
    },
    getProductNumlist(parm) {
      if (!parm) parm = {};
      getProductNumlist(parm).then((res) => {
        this.dataSource = [];
        res.items.forEach((item) => {
          item.transferProductionNum = 0;
          this.dataSource.push(item);
        });
      });
    },
    handleChange(e) {
      console.log(e);
      this.value = e;

      console.log(this.dataSource);
    },
    /**
     * 列表切换分页事件
     * @param {obj} pagination [当前分页参数]]
     */
    handleTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination ? pagination.current : pager.current;
      this.pagination = pager;
      var parm = {
        skipCount: (this.pagination.current - 1) * this.pagination.pageSize,
      };
      this.getProductNumlist(parm);
    },
    ProductionDelivery() {
      var pram = {};
      var datas = [];
      this.dataSource.forEach((item) => {
        var data = {
          orderDetailID: item.id,
          num: item.transferProductionNum,
        };
        datas.push(data);
      });
      pram.factoryID = "3fa85f64-5717-4562-b3fc-2c963f66afa6"; //仓库ID
      pram.orderDetails = datas; 
      updateProduction(pram).then((res) => {
        this.$message.success("销售订单转生产成功");
      });
    },
  },
  watch: {
    productionDeliverySalesId: {
      handler() {
        if (this.productionDeliverySalesId != "") {
          this.getProductNumlist({
            salesID: this.productionDeliverySalesId,
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.tip {
  background: #eee;
  padding: 8px;
  margin-bottom: 16px;
}

.sel {
  margin-bottom: 16px;
  label {
    font-weight: bold;
  }
}
</style>
