<template>
  <div>
    <div class="desc">
      <div><label>订单状态：</label><span>已完成</span></div>
      <div><label>订单生成时间：</label><span>2021-08-10 10:47:58</span></div>
      <div><label>订单关闭时间：</label><span>2021-08-11 10:47:58</span></div>
    </div>
    <div class="tbhead">订单出库记录</div>
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :rowKey="(record) => record.id"
      :pagination="false"
    >
      <template slot="creationTime" slot-scope="text">
        {{ text | filterDate }}
      </template>
    </a-table>
  </div>
</template>
<script>
const columns = [
  {
    title: "创建时间",
    dataIndex: "creationTime",
    scopedSlots: { customRender: "creationTime" },
  },
  {
    title: "出库仓库",
    dataIndex: "docVersion",
  },
  {
    title: "出库单号",
    dataIndex: "docUploader",
  },
];

export default {
  data() {
    return {
      dataSource: [],
      columns,

      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
      },
    };
  },
  filters: {
    filterDate(val) {
      if (val) {
        let dt = val.substring(0, 19).replace("T", " ");
        return dt;
      } else {
        return "";
      }
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.dataSource = [
        {
          figureDocumentId: "e90b7f32-2f93-3fae-46f6-39fee1672885",
          docCode: "TD210910-0004",
          isNewestVersion: true,
          docName: "大撒大撒",
          docType: "15f1e8de-13c1-8275-8a03-39fe0d689003",
          docFormat: "xls",
          docVersion: "V1.0",
          docSize: 26,
          docGuid: "c0c70b5d-cb14-4ec6-b1b2-818a04d73092",
          docUrl:
            "https://yungongchang.oss-cn-shenzhen.aliyuncs.com/hardwaresaas/file/20210910/c0c70b5d-cb14-4ec6-b1b2-818a04d73092.xls",
          docFullPath:
            "hardwaresaas/file/20210910/c0c70b5d-cb14-4ec6-b1b2-818a04d73092.xls",
          filePreviewAddress:
            "http://view.officeapps.live.com/op/view.aspx?src=https://yungongchang.oss-cn-shenzhen.aliyuncs.com/hardwaresaas/file/20210910/c0c70b5d-cb14-4ec6-b1b2-818a04d73092.xls",
          docUploader: "大佬瑞总",
          docLastModifierName: "大佬瑞总",
          docLastModifierId: "c48be630-cb65-4287-9eca-e5f0e1cbea20",
          docLastModificationTime: "2021-09-10T18:21:50.5973771",
          creationTime: "2021-09-10T18:21:50.615252",
          creatorId: "c48be630-cb65-4287-9eca-e5f0e1cbea20",
          id: "4570cd89-7a07-1337-52d4-39fee1672885",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.desc {
  margin-bottom: 16px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 8px;
  > div {
    line-height: 32px;
    label {
      font-weight: bold;
    }
  }
}

.tbhead {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
}
</style>
