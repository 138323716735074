import {
    postRequest,
    updateRequest,
    getRequest,
} from "@/utils/http";

/* 获取仓库列表 */
export const getWarehouseList = (param) => getRequest(`warehouseManage/warehouse`, param);

/* 获取仓库列表 */
export const getDropdownList = (param) => getRequest(`warehouseManage/warehouse/dropdown`, param);


